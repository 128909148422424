function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var noop = function noop() {
  return {};
};

var width = 2560;
var height = 1440;
var dims = {
  y: 0,
  x: 0,
  width: width,
  height: height,
  innerWidth: width,
  innerHeight: height,
  clientWidth: width,
  clientHeight: height
};
var elemProps = {
  parentNode: _objectSpread(_objectSpread({}, dims), {}, {
    getBoundingClientRect: function getBoundingClientRect() {
      return dims;
    },
    removeChild: noop,
    appendChild: noop,
    setAttribute: noop
  }),
  removeChild: noop,
  appendChild: noop,
  setAttribute: noop
};

var elem = _objectSpread(_objectSpread(_objectSpread({}, dims), elemProps), {}, {
  getBoundingClientRect: function getBoundingClientRect() {
    return dims;
  }
});

var event = {
  initCustomEvent: noop,
  createEvent: noop,
  composedPath: noop,
  initEvent: noop,
  preventDefault: noop,
  stopImmediatePropagation: noop,
  stopPropagation: noop
};

var documentMock = _objectSpread({
  addEventListener: noop,
  removeEventListener: noop,
  createEvent: function createEvent() {
    return event;
  },
  createElement: function createElement() {
    return elem;
  },
  getElementById: function getElementById() {
    return elem;
  },
  getElementsByClassName: function getElementsByClassName() {
    return [elem];
  },
  getElementsByTagName: function getElementsByTagName() {
    return [elem];
  },
  querySelector: function querySelector() {
    return [elem];
  },
  documentElement: elem,
  activeElement: elem,
  style: dims
}, dims);

documentMock.body = documentMock;
var locationMock = {
  href: 'http://mock.wix.com/',
  protocol: 'http:',
  host: 'mock.wix.com',
  hostname: 'mock.wix.com',
  port: '',
  pathname: '/',
  search: '',
  hash: ''
};

var windowMock = _objectSpread({
  isMock: true,
  isSSR: true,
  orientation: 0,
  devicePixelRatio: 1,
  scrollTop: 0,
  addEventListener: noop,
  removeEventListener: noop,
  createEvent: noop,
  CustomEvent: noop,
  screen: dims,
  open: noop,
  petri: {},
  search: {},
  location: locationMock,
  postMessage: noop,
  requestAnimationFrame: noop,
  dispatchEvent: noop,
  document: documentMock,
  getComputedStyle: noop,
  localStorage: {},
  frames: []
}, dims);

windowMock.parent = windowMock;
export default windowMock;